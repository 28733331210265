<template>
  <v-container class="page">
    <div class="page-header pb-2">
      <v-btn @click="close()" icon color="blue darken-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-checkbox
        dense
        small
        hide-details
        v-model="autoplay"
        color="blue darken-2"
        class="mt-0 pt-0"
        @click="toggleAutoplay"
      ></v-checkbox>
      <div class="text-caption">Autoplay</div>
    </div>

    <div class="page-content">
      <v-img
        :src="require('@/mockups/ABC/book-1/assets/images/1 6-2.png')"
        max-width="250"
      >
      </v-img>
      <div class="my-3" v-if="language == 1 || language == 2">
        Hamochi broke his invention.
      </div>
      <div class="my-3" v-if="language == 1 || language == 3">
        哦，不！不小心失手了。
      </div>
    </div>
    <div class="page-footer">
      <div class="text-caption error--text" v-if="isError">
        Error loading audio
      </div>
      <div class="buttons">
        <v-btn @click="goPrevious()" icon class="mx-2" color="blue darken-2">
          <v-icon x-large>mdi-menu-left</v-icon>
        </v-btn>
        <v-btn
          @click="playAudio()"
          icon
          class="mx-2"
          color="blue darken-2"
          :disabled="isLoading || isError"
          :loading="isLoading"
        >
          <v-icon v-if="isPlaying" large>mdi-pause</v-icon>
          <v-icon v-else large>mdi-volume-high</v-icon>
        </v-btn>
        <v-btn @click="goNext()" icon class="mx-2" color="blue darken-2">
          <v-icon x-large>mdi-menu-right</v-icon>
        </v-btn>
        <v-btn
          @click="removeAuto()"
          icon
          class="mx-2 count-down"
          color="blue darken-2"
          v-if="interval"
        >
          {{ countDown }}
        </v-btn>
      </div>
      <div class="mt-2 text-caption">page 2 of 100</div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    isPlaying: false,
    currentAudio: "E",
    autoplay: false,
    language: 1,
    timer: null,
    interval: null,
    countDown: 3,
    isLoadedE: false,
    isLoadedZ: false,
    isLoading: true,
    isError: false,
  }),
  created() {
    //
  },
  mounted() {
    if (this.$route.query.lang) {
      this.language = this.$route.query.lang;
    }

    if (this.language == 1 || this.language == 2) {
      this.audioE = new Audio(
        require("@/mockups/ABC/book-1/assets/audio/7-e.wav")
      );
      this.currentAudio = "E";
      this.audioE.load();
      this.audioE.oncanplaythrough = () => {
        this.isLoadedE = true;
        if (this.language == 2 || this.isLoadedZ) {
          this.timer = setTimeout(() => {
            this.isLoading = false;
            if (this.autoplay && !this.isError) {
              this.playAudio();
            }
          }, 500);
        }
      };
      this.audioE.onerror = () => {
        this.isError = true;
        this.isLoading = false;
      };
    } else {
      this.currentAudio = "Z";
    }

    if (this.language == 1 || this.language == 3) {
      this.audioZ = new Audio(
        require("@/mockups/ABC/book-1/assets/audio/7-z.wav")
      );
      this.audioZ.load();
      this.audioZ.oncanplaythrough = () => {
        this.isLoadedZ = true;
        if (this.language == 3 || this.isLoadedE) {
          this.timer = setTimeout(() => {
            this.isLoading = false;
            if (this.autoplay && !this.isError) {
              this.playAudio();
            }
          }, 500);
        }
      };
      this.audioZ.onerror = () => {
        this.isError = true;
      };
    }

    this.autoplay =
      this.$route.query.autoplay && this.$route.query.autoplay == 1
        ? true
        : false;
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    if (this.timer) {
      clearInterval(this.interval);
      this.countDown = 3;
    }
    if (this.audioZ) {
      this.audioZ.pause();
      this.audioZ = null;
    }
    if (this.audioE) {
      this.audioE.pause();
      this.audioE = null;
    }
  },
  methods: {
    playAudio() {
      if (!this.isPlaying) {
        if (this.currentAudio == "E" && this.isLoadedE) {
          this.audioE.play();
          this.audioE.onplay = () => {
            this.isPlaying = true;
          };
          this.audioE.onended = () => {
            if ((this.language == 1 || this.language == 3) && this.isLoadedZ) {
              this.audioZ.play();
              this.currentAudio = "Z";
              this.audioZ.onended = () => {
                this.isPlaying = false;
                if (this.language == 1 || this.language == 2) {
                  this.currentAudio = "E";
                }
                this.autoNext();
              };
            } else {
              this.isPlaying = false;
              this.autoNext();
            }
          };
        } else if (this.isLoadedZ) {
          this.audioZ.play();
          this.audioZ.onplay = () => {
            this.isPlaying = true;
          };
          this.currentAudio = "Z";
          this.audioZ.onended = () => {
            this.isPlaying = false;
            if (this.language == 1 || this.language == 2) {
              this.currentAudio = "E";
            }
            this.autoNext();
          };
        }
      } else {
        if (this.audioZ) {
          this.audioZ.pause();
        }
        if (this.audioE) {
          this.audioE.pause();
        }
        this.isPlaying = false;
      }
    },
    toggleAutoplay() {
      this.$router.replace({
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    goPrevious() {
      this.$router.push({
        name: "ABCPage9",
        query: {
          autoplay: this.autoplay ? 1 : 0,
          lang: this.language,
          transition: "slide-right",
        },
      });
    },
    goNext() {
      this.$router.push({
        name: "ABCPage16",
        query: {
          autoplay: this.autoplay ? 1 : 0,
          lang: this.language,
          transition: "slide-left",
        },
      });
    },
    close() {
      this.$router.push({
        name: "ABCPage2",
      });
    },
    autoNext() {
      this.interval = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          clearInterval(this.interval);
          this.goNext();
        }
      }, 1000);
    },
    removeAuto() {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
};
</script>

<style scoped>
.page-content,
.page-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
  position: relative;
}

.vocab {
  text-decoration: underline;
  font-weight: 900;
}

.count-down {
  position: absolute;
  right: -40px;
  top: 0;
}
</style>